import {FeatureFlagsService} from '../../libraries/feature-flags/feature-flags.service';
import {AuthService} from '../../store/auth';
import {Router} from '@angular/router';
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {GleapService} from "../../libraries/gleap/gleap.service";
import {ProductFruitsService} from "../services/product-fruits.service";

export function eventeeInitializerFactory(authService: AuthService,
										  featureFlagsService: FeatureFlagsService,
										  router: Router,
										  gleapService: GleapService,
										  productFruitsService: ProductFruitsService) {
	return async () => {
		await FeatureFlagsService.growthBookLoader(featureFlagsService);

		if (localStorage.getItem('token') && !location.href.includes('o-auth-redirect')) {
			try {
				if (!!environment.gleap.key) {
					gleapService.init();
				}
				const user = await firstValueFrom(authService.me());

				if (!!environment.productFruits.key) {
					productFruitsService.initProductFruits(user)
				}

				featureFlagsService.setGrowthBookAttributes({
					userEmail: user.email,
					country: user.country
				});
			} catch (e) {
				try {
					await firstValueFrom(authService.logout());
				} catch (e) {
					console.error(e);
				} finally {
					await router.navigate(['auth']);
				}
			}
		}
	}
}
