import {Injector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LOCATION_INITIALIZED} from '@angular/common';

/**
 * THIS IS JUST SO THE NGX TRANSLATE WAITS FOR TRANSLATION TO BE LOADED, THEN YOU CAN USE translateService.instant() everywhere
 */
export function appLanguagesInitializerFactory(translate: TranslateService, injector: Injector) {
	return async () => {
		await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

		const lang = localStorage.getItem('lang');

		translate.setDefaultLang(lang || 'en');
		translate.use(lang || 'en');
	}
}
