import {inject, Injectable, NgModule} from '@angular/core';
import {
	ActivatedRouteSnapshot,
	createUrlTreeFromSnapshot,
	PreloadingStrategy,
	Route,
	RouterModule,
	Routes
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {OAuthRedirectComponent} from './domains/o-auth-redirect/o-auth-redirect.component';
import {AuthQuery} from "./store/auth";

@Injectable()
export class CustomPreloader implements PreloadingStrategy {
	// tslint:disable-next-line:ban-types
	preload(route: Route, load: Function): Observable<any> {
		return route.data && route.data.preload ? load() : of(null);
	}
}

const routes: Routes = [
	{
		path: '',
		redirectTo: 'admin',
		pathMatch: 'full'
	},
	{
		path: 'admin',
        canActivate: [(next: ActivatedRouteSnapshot) => {
            if (inject(AuthQuery).isLoggedIn()) {
                return true;
            } else {
				return createUrlTreeFromSnapshot(next, ['/', 'auth']);
            }
        }],
		loadChildren: () => import('src/app/domains/admin/admin.module')
			.then(m => m.AdminModule)
	},
	{
		path: 'auth',
		loadChildren: () => import('src/app/domains/auth/auth.module')
			.then(m => m.AuthModule),
		data: {preload: true}
	},
	{
		path: 'payment',
		loadChildren: () => import('src/app/domains/payment/payment.module')
			.then(m => m.PaymentModule),
		data: {
			hideHeader: true
		}
	},
	{
		path: 'o-auth-redirect',
		component: OAuthRedirectComponent
	},
	{
		path: '**',
		redirectTo: 'auth'
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		preloadingStrategy: CustomPreloader,
		paramsInheritanceStrategy: 'always'
	})],
	exports: [RouterModule],
	providers: [CustomPreloader]
})
export class AppRoutingModule {
}
