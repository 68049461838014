import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateCompiler, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';

// @ts-ignore
import i18nMap from '../../../assets/i18n/autogen/map.json';
import {LOCATION_INITIALIZED} from '@angular/common';

export const supportedLanguages = ['en', 'cs', 'de'];

@NgModule({
	imports: [
		HttpClientModule,
		TranslateModule.forRoot({
			compiler: {
				provide: TranslateCompiler,
				useClass: TranslateMessageFormatCompiler
			},
			loader: {
				provide: TranslateLoader,
				useFactory: translateLoaderFactory,
				deps: [HttpClient]
			}
		})
	],
	exports: [TranslateModule],
	providers: [
		{provide: MESSAGE_FORMAT_CONFIG, useValue: {locales: supportedLanguages}},
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, Injector],
			multi: true
		}
	]
})
export class I18nModule {
	constructor(
		translate: TranslateService
	) {
		translate.addLangs(supportedLanguages);
		let browserLang = getLanguage() || translate.getBrowserLang() || 'en';
		translate.use(browserLang);
		moment.locale(browserLang);
	}
}

export class TranslateBrowserLoader implements TranslateLoader {
	constructor(
		private http: HttpClient,
		private prefix: string = 'i18n',
		private suffix: string = '.json',
	) { }

	public getTranslation(lang: string): Observable<any> {
		const suffix = `.${i18nMap[lang]}${this.suffix}`;
		return new TranslateHttpLoader(this.http, this.prefix, suffix).getTranslation(lang);
	}
}

export function translateLoaderFactory(httpClient: HttpClient) {
	const prefix = './assets/i18n/autogen/';
	return new TranslateBrowserLoader(httpClient, prefix);
}

/**
 * THIS IS JUST SO THE NGX TRANSLATE WAITS FOR TRANSLATION TO BE LOADED, THEN YOU CAN USE translateService.instant() everywhere
 */
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
	return async () => {
		await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

		let lang = getLanguage() || translate.getBrowserLang() || 'en';

		translate.setDefaultLang(lang || 'en');
		translate.use(lang || 'en');
	}
}

export function getLanguage() {
	let lang = localStorage.getItem('lang') || Cookies.get('LANG');
	if (supportedLanguages.indexOf(lang) === -1) {
		lang = 'en';
	}
	return lang;
}
