import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-o-auth-redirect',
	templateUrl: './o-auth-redirect.component.html',
	styleUrls: ['./o-auth-redirect.component.scss']
})
export class OAuthRedirectComponent implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}

}
